import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

const NotFoundPage = ({ location }) => (
  <Layout>
    <SEO title="404: Not found" description="" pathname={location.pathname} />
    <div className="section">
      <div className="container">
        <h1 className="title has-text-centered is-size-1">
          404: Seite nicht gefunden
        </h1>
        <p className="has-text-centered">
          <Link to="/" className="button is-link">
            <span>Hier geht es zurück zur Startseite</span>
            <span className="icon">
              <FaArrowRight size="12" />
            </span>
          </Link>
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
